import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import AccountingMonthlyCardsList from "@/components/AccountingMonthlyCardsList.vue";

const defaultProps = {
  accountingYear: 2028,
  accountingInAYear: {
    amountOfIncome: { amount: 123, currency: "EUR" },
    amountOfCharges: { amount: 567, currency: "EUR" },
    items: [
      {
        month: 10,
        invoice: {
          uuid: "111222-azxd1-asd",
          downloadUrl: "www.test.es",
          viewUrl: "www.test.es",
          url: "www.test.es",
          expiresAt: "string",
        },
        invoices: [
          {
            uuid: "111222-azxd1-asd",
            downloadUrl: "www.test.es",
            viewUrl: "www.test.es",
            url: "www.test.es",
            expiresAt: "string",
          },
        ],
        totalAmount: {
          amount: 1234,
          currency: "EUR",
        },
        movements: [
          {
            type: "monthlyIncome",
            concept: "contract august",
            amount: {
              amount: 1234,
              currency: "EUR",
            },
            date: "2023-01-23",
          },
        ],
      },
    ],
  },
};

jest.mock("@/services/MyRentalsConfig");

const component = (props = {}) => {
  return myRentalsShallowMount(AccountingMonthlyCardsList, {
    props: { ...defaultProps, ...props },
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("AccountingMonthlyCardsList", () => {
  testMyRentalsShallowMount(AccountingMonthlyCardsList, {
    props: defaultProps,
  });

  describe("Methods", () => {
    describe("handleInvoicesButtonClick", () => {
      it("should return without setting a selectedItem if item.invoices passed by props is empty", () => {
        // given
        const mockedItem = {
          invoices: [],
        };
        const wrapper = component();
        // when
        wrapper.vm.handleInvoicesButtonClick(mockedItem);
        // then
        expect(wrapper.vm.selectedItem).toBe(null);
      });
      it("should call method openFile with correct params and not set selectedItem if item.length is 1", () => {
        // given
        const mockedUrl = "www.test-url.es";
        const mockedItem = {
          invoices: [{ viewUrl: mockedUrl }],
        };
        const wrapper = component();
        spyOn(wrapper.vm, "openFile");
        // when
        wrapper.vm.handleInvoicesButtonClick(mockedItem);
        // then
        expect(wrapper.vm.openFile).toHaveBeenCalledWith(mockedUrl);
        expect(wrapper.vm.selectedItem).toBe(null);
      });
      it("should not call method openFile but set selectedItem with item passed by params if item.length is > 1", () => {
        // given
        const mockedUrl = "www.test-url.es";
        const mockedItem = {
          invoices: [{ viewUrl: mockedUrl }, { viewUrl: mockedUrl }],
        };
        const wrapper = component();
        spyOn(wrapper.vm, "openFile");
        // when
        wrapper.vm.handleInvoicesButtonClick(mockedItem);
        // then
        expect(wrapper.vm.openFile).not.toHaveBeenCalled();
        expect(wrapper.vm.selectedItem).toEqual(mockedItem);
      });
    });
  });
});
